// @ts-nocheck
import '../App.css';
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Layout from '../layout';
import Button from './controls/RoundButton';
import TimePicker from './timepicker';
import linkPhoto from '../icons/edit.svg';
import postponeIcon from '../icons/postpone.svg'
import { useDispatch, useSelector } from 'react-redux';
import { clearsuccess, confirmInfo, modifyDate, setTime } from '../store/actions/appactions';
import moment from 'moment';
import Yellow from '../icons/yellow.svg';
import Location from '../icons/location.svg';
import Footer from './footer';
import { useHistory, useParams } from 'react-router-dom';
import { SpecialCharDisplay } from './utils';
import { getDeliveryInfo } from '../store/actions/appactions';
import { WoraCorbosServiceDeliveriesDeliveryDto, WoraCorbosServiceDeliveryStepsStepType } from '../proxy/Api';
import { IConfig, limitations } from '../common/constants/limits-dictionary';
import GenericModal from './genericModal';
import ConfirmationModal from './confirmationModal';
import { store } from '../store/store';
import { confirm_STATELESS, modifyInfo, modifyInfo_STATELESS } from '../store/actions/appactions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
enum STATE {
  READY,
  FORTOMORROW,
  NOETA,
  FAILED
}
function ConfirmScreen(props: any) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    icons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      width: '90%',
      marginTop: 20,
      marginBottom: 20
    },
    iconss: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      width: '90%',
      height: 80,
      marginTop: 50
    },
    rounddiv: {
      height: '15px',
      width: '15px',
      borderRadius: '7.5px',
      backgroundColor: 'orange'
    },
    heading: {
      textAlign: 'center',
      color: '#000',
      fontSize: 15,
      maxWidth: 500,
      // fontWeight: 500,
      marginBottom: 20
    },
    headingtwo: {
      fontWeight: 700,
      fontSize: 24,
      maxWidth: '80%',
      textAlign: 'center',
      color: '#393939',
      marginTop: '-10px'
    },
    headingtwotwo: {
      fontWeight: 700,
      fontSize: 20,
      maxWidth: '80%',
      textAlign: 'center',
      color: '#393939',
      marginTop: '-10px'
    },
    headingalt: {
      fontWeight: 700,
      fontSize: 24,
      maxWidth: '80%',
      textAlign: 'center',
      color: '#393939',
      paddingTop: 20,
      margin: 0
    },
    buttoncontainer: {
      width: '90%',
      textAlign: 'center',
      marginTop: 0,
      marginBottom: 20
    },
    headingsix: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 5,
      color: '#393939',
      textAlign: 'center'
    },
    headingsixsix: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 3,
      color: '#393939',
      textAlign: 'center',
      marginTop: 30
    },
    //  spantext: {
    //      fontSize: 18,
    //      fontWeight: '400',
    //      maxWidth: '80%',
    //      textAlign: 'center',
    //      color: '#393939',
    //      marginTop: '-10px'
    //  },
    spantexttwotwo: {
      fontSize: 16,
      marginTop: '-50px',
      marginBottom: 20,
      fontWeight: 400,
      color: '#000',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    spantext: {
      fontSize: 16,
      fontWeight: 400,
      maxWidth: '80%',
      textAlign: 'center',
      color: '#000',
      // marginTop: '-10px'
    },
    spantexttwo: {
      textAlign: 'center',
      fontSize: 16,
      marginTop: 10,
      fontWeight: 400,
      color: '#000',
      textDecoration: 'none'
    },
    spantextthree: {
      textAlign: 'center',
      fontSize: '14px !important',
      marginTop: 20,
      fontWeight: 400,
      color: '#000',
      textDecoration: 'none'
    },
    headingone: {
      fontSize: 37,
      fontWeight: 800
    },
    icontext: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: 800,
      textDecoration: 'none',
      color: 'black'
    },
    icontexttwo: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 9,
      fontWeight: 800,
      textDecoration: 'none',
      color: 'black'
    },
    iconsocial: {
      marginRight: 5,
      width: 15
    },
    typocontainer: {
      width: '90%'
    },
    pageImage: {
      width: 250,
      paddingTop: 20,
    },
    pleaseContactCSifAway: {
      marginTop: 30,
      marginLeft: 10,
      textAlign: 'center'
    },
    infotxt: {
      color: '#9f0ba6',
      fontWeight: 600
    },
    addresstxt: {
      marginBottom: 10
    },
    nottoday: {
      fontSize: 20,
      textAlign: 'center',
      padding: 40,
      marginTop: 80,
      marginBottom: 80
    },
    warningtxt: {
      color: 'red',
      marginTop: 20
    }
  }));
  const classes = useStyles();
  const { token } = useParams<{ [key: string]: string }>();
  const dispatch = useDispatch();
  const app = useSelector((state: any) => state.app);
  const data = useSelector((state: any) => state.app?.userinfo == null ? null : state.app?.userinfo as WoraCorbosServiceDeliveriesDeliveryDto);
  let history = useHistory();
  const delivery = data?.steps?.find(elemento => elemento.stepType === WoraCorbosServiceDeliveryStepsStepType.Value1);

  const [storeSettings, setStoreSettings] = useState<IConfig | undefined>(undefined)

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const time_from = (delivery)
    ? delivery.startEtaWindow
    : null;
  const time_until = (delivery)
    ? delivery.endEtaWindow
    : null;

  const [isDataLoaded, setIsDataLoaded] = useState(false); // Aggiunto per tracciare il caricamento dei dati

  const [interphone, setInterphone] = useState(data?.deliveryInterPhone)

  const isAboutToArrive = delivery?.status == 4;
  const isConfirmed = delivery?.windowConfirmed == true || delivery?.changedDateByUser == true;
  const isCompletedSuccessfully = delivery?.status == 5;



  const isRequestedDateAfterToday = moment(delivery?.requested).isAfter(new Date(), "day")
  const isNotReady = delivery?.status == 1 || delivery?.status == 6 || !moment(delivery?.taskEta).isSame(moment(new Date()), 'D')

  const isFailed = delivery?.status == 9
  const DELIVERYSTATE = () => {
    if (isFailed == true) {
      return STATE.FAILED
    }
    if (isRequestedDateAfterToday == true) {
      return STATE.FORTOMORROW
    }
    if (isNotReady == true) {
      return STATE.NOETA
    }
    return STATE.READY
  }


  const [isNeededConfirmation, setIsNeededConfirmation] = useState(false)
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function getNextDayIgnoringWeekends(date: any) {
    const dateobj = moment(date)

    console.log("NEXTDAY! for: ", dateobj.day())
    if (dateobj.day() >= 1 && dateobj.day() <= 4) {
      dateobj.add(1, "d")
    }
    else if (dateobj.day() == 5) {
      dateobj.add(3, "d")
    }
    return dateobj
  }

  /** Confierm data and time */
  const onSubmit = () => {
    const requireddate = moment(time_from).format("DD-MM-YYYY");
    const requiredfrom = moment(time_from).format('HH:mm');
    const requireduntil = moment(time_until).format('HH:mm');
    const fields = {
      time_from: `${requireddate} ${requiredfrom}`,
      time_until: `${requireddate} ${requireduntil}`,
      pwa_status: 'Confirmed',
      mod_time: 1
    }

    const json_data = JSON.stringify(fields);
    dispatch(confirmInfo(json_data, app.token, app.sessionName, requiredfrom, requireduntil, requireddate));
    // dispatch(modifyInfo(app.token, '1', app.sessionName, requiredfrom, requireduntil, requireddate))
  }

  useEffect(() => {
    //if the driver is driving to deliver this delivery, then redirect to tracking
    if (isAboutToArrive == true)
      history.push("/tracking/" + token);
    if (isCompletedSuccessfully == true)
      history.push('/success/' + token);
  }, [isAboutToArrive, isCompletedSuccessfully])

  useEffect(() => {
    if (app.modifysuccess) {
      dispatch(clearsuccess())
    }
  }, [app.successconfirm, token, history])

  useEffect(() => {
    if (app.successconfirm) {
      history.push("/thankyouscreen/" + token);
    }
  }, [app.successconfirm, token, history])

  /** Load  data */
  useEffect(() => {
    if (token !== undefined) {
      dispatch(getDeliveryInfo(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getDeliveryInfo(token));
    }, 50 * 1000);

    return () => clearInterval(interval);
  }, [])


  useEffect(() => {
    if (app.error) {
      //history.push("/err/");
    }
  }, [app.error]);



  useEffect(() => {
    if (app.userinfo) {
      setIsDataLoaded(true); // Imposta isDataLoaded su true quando i dati sono caricati
      console.log(delivery?.city);
      let genericString = delivery?.city == "Milano"?"GENERICMILAN":"GENERICROME"
      console.log(genericString)
      let settings = limitations[app.userinfo.storeId]??limitations[genericString]
      setStoreSettings(settings)
    }
  }, [app.userinfo]);

  // resize window
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (app.modifysuccess) {
      console.log("SUCC::::", app.modifysuccess)
    }
  }, [app.modifysuccess])

  useEffect(()=>{
    setInterphone(data?.deliveryInterPhone)
  },[data])

  const onLinkClick = () => {

    dispatch(setTime(
      time_from,
      time_until
    ))
    history.push({
      pathname: '/change-date/' + token,
    });
  }

  const onLinktwoClick = () => {
    history.push("/address/" + token, { datehaschanged: false });
  }

  if (!isDataLoaded) {
    return <div>Caricamento in corso...</div>; // o qualsiasi altro elemento di caricamento
  }

  const showSuccess = () => toast.success("Citofono salvato!", {position: "bottom-right", autoClose: 2000});


  return (
    <Layout>

      <ConfirmationModal open={isNeededConfirmation} content={"Conferma di posticipare al " + getNextDayIgnoringWeekends(time_from).format("D/MM")} handleClose={() => { setIsNeededConfirmation(false) }} confirmClicked={() => {
        let timeobjfrom = moment(time_from)
        timeobjfrom.set({ "h": 9, "m": 0, "s": 0, "ms": 0 })
        timeobjfrom = getNextDayIgnoringWeekends(timeobjfrom)
        let timeobjuntil = moment(time_until)
        timeobjuntil.set({ "h": 21, "m": 0, "s": 0, "ms": 0 })
        timeobjuntil = getNextDayIgnoringWeekends(timeobjuntil)
        dispatch(modifyDate(token, timeobjfrom.utc().format(), timeobjuntil.utc().format()));
      }}></ConfirmationModal>
      <GenericModal
        content={"La ricontatteremo nel giorno concordato per organizzare un appuntamento di consegna"}
        open={app.modifysuccess ?? false}
        handleClose={() => {
          dispatch(clearsuccess())
        }} />
      {/* {"isRequestedDateAfterToday:"}{JSON.stringify(isRequestedDateAfterToday)} */}
      {DELIVERYSTATE() == STATE.FORTOMORROW && <div className={classes.root}>
        <div className={classes.nottoday}>
          In data {moment(delivery?.requested).format("D/MM")} la ricontatteremo per programmare un nuovo appuntamento di consegna.
        </div>
        <div className={classes.iconss}>
          <Footer />
        </div>
      </div>}
      {DELIVERYSTATE() == STATE.FAILED && <div className={classes.root}>
        <div className={classes.nottoday}>
          Abbiamo effettuato un tentativo di consegna nell'orario indicato, ma non siamo riusciti a consegnare il suo ordine. Restiamo in attesa di sue indicazioni per programmare un nuovo appuntamento di consegna.
        </div>
        <div className={classes.iconss}>
          <Footer />
        </div>
      </div>}
      {DELIVERYSTATE() == STATE.NOETA && <div className={classes.root}>
        <div className={classes.nottoday}>
          L'ordine non è ancora pronto, le manderemo una notifica tramite WhatsApp appena lo processiamo.
        </div>
        <div className={classes.iconss}>
          <Footer />
        </div>
      </div>}
      {DELIVERYSTATE() == STATE.READY && <div className={classes.root}>
        <div className={classes.icons}>

        </div>
        {/* <h2 className={windowDimensions.height <= 667 ? classes.headingtwotwo : classes.headingtwo}>Gentile Sig.ra {app?.userinfo?.customer_name_delivery || '-' }</h2> */}
        <span className={classes.spantext}>
          <span>{`Gentile ${app?.userinfo?.deliveryContactName ?? app?.userinfo?.deliveryContactSurname}, il suo ordine `}</span>
          <span><b><span style={{ textTransform: 'uppercase' }}>{app?.userinfo?.businessPartnerDescription}</span> {app?.userinfo?.externalOrderCode}</b></span>
          <span>
            {` è in consegna oggi nella fascia oraria:`}
            {/* { app?.userinfo?.task_type === 'Delivery' 
                ?  <> { moment(time_from).isAfter(new Date(), 'day') ? 'Domani consegneremo il suo ordine' : 'Oggi consegneremo il suo ordine'  } </> 
                : 'Oggi ritireremo il suo ordine'
            } */}
          </span>
        </span>
        {/* <h2 className={classes.headingalt}>{app?.userinfo?.storeName || '-'}</h2> */}
        {/* <h6 className={windowDimensions.height <= 667 ? classes.headingsixsix : classes.headingsix}>nella fascia oraria</h6> */}
        <br />
        <TimePicker onClick={() => {
          if (storeSettings?.CAN_POSTPONE_TIME && !isConfirmed) {
            //onLinkClick()
          }
        }} words={`${moment(time_from).format('HH:mm')} - ${moment(time_until).format('HH:mm')}`}
          color={undefined} />
        <div className={classes.buttoncontainer}>
          <Button text="RIVEDI I DATI E CONFERMA" variant="contained" onClick={() => onLinktwoClick()} loading={app.confirmloading}
            link={undefined} disabled={isConfirmed} />
          {isConfirmed && <span className={classes.infotxt}>La fascia oraria è gia' stata confermata</span>}

        </div>
        <div className={classes.heading}>
          {
            <>{app?.userinfo?.contactTo}</>
          }
          <br />
          <div className={classes.addresstxt}>
            <SpecialCharDisplay value={app?.userinfo?.to} />
          </div>
          <div style={{textAlign:"center",display:"flex"}}>
             Citofono: <input size="30" style={{borderColor:!interphone?"red":"purple",borderRadius:30,textAlign:"center",marginLeft:3, borderWidth:2}} value={interphone} onChange={(evt)=>{setInterphone(evt.target.value)}} placeholder={!interphone && "Citofono mancante"}/> 
            <button onClick={()=>{
              modifyInfo_STATELESS(
                delivery!.contactId!.toString(),
                token,
                delivery?.address,
                delivery?.addressNumber,
                delivery?.zipCode,
                delivery?.city,
                delivery?.province,
                delivery?.note,
                delivery?.lat,
                delivery?.lng,
                interphone,
                delivery?.reception
              ).then(x => {
                if (x != 0) {
                  history.push("/err")
                }
                else {
                  showSuccess()
                }
              })
            }} style={{backgroundColor:!interphone?"red":"purple",borderRadius:30,textAlign:"center",marginLeft:3,borderWidth:1,borderColor:!interphone?"red":"purple",color:"white"}}>Salva</button>
          </div>
        </div>
        {
          (storeSettings?.CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE && !isConfirmed) &&
          <div onClick={() => {
            setIsNeededConfirmation(true)
          }} className={windowDimensions.height <= 568 ? classes.spantextthree : classes.spantexttwo} style={{
            cursor: 'pointer', borderRadius: 20,
            backgroundColor: "#f5bf04",
            padding: 6,
            paddingLeft: 15,
            paddingRight: 15
          }}>
            <span className={windowDimensions.height <= 568 ? classes.spantextthree : classes.spantexttwo}><img src={postponeIcon} className={classes.iconsocial} alt="Linkimg" />
              POSTICIPA CONSEGNA AL {getNextDayIgnoringWeekends(time_from).format("D/MM")}</span></div>
        }
        {
          storeSettings?.CAN_POSTPONE_TIME && !isConfirmed &&
          <div onClick={() => onLinkClick()} className={windowDimensions.height <= 568 ? classes.spantextthree : classes.spantexttwo} style={{ cursor: 'pointer' }}>
            <span className={windowDimensions.height <= 568 ? classes.spantextthree : classes.spantexttwo}><img src={linkPhoto} className={classes.iconsocial} alt="Linkimg" />
              MODIFICA ORARIO</span></div>
        }
        <div className={classes.iconss}>
          <Footer />
        </div>
      </div>}
      <ToastContainer />

    </Layout>


  )
}

export default ConfirmScreen;
